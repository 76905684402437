import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';

import { AuthProvider } from "./context/auth.context";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
       <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
