import React from 'react';

import CategoryDetails from './categoryDetails';

const Businesses =()=>{
    return(
        <div>
            <CategoryDetails />
        </div>
    ) 
}

export default Businesses;