import React from "react";

import {Row, Col} from "react-bootstrap"

const DetailsReview = () => {
  return (
    <div className="mt-5 p-4 border rounded-lg shadow-lg max-w-xl mx-auto">
        <div>
            
        </div>

        <Row>
            <Col>
            </Col>
            <Col>
            </Col>
        </Row>
    </div>
  );
};

export default DetailsReview;
